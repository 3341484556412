// App.js
import { useEffect } from "react";
import './App.css';
import Login from "./Login/Login";
import SideBar from "./SideBarAdmin/Menu/SideBar";
import MenuUser from "./SideUser/MenuUser";
import Profile from "./Profile/Profile";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) {
      // Redirect to login page if user is not logged in
      navigate("/");
    }
  }, [navigate]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/sidebar" element={<SideBar />} />
        <Route path="/menuuser" element={<MenuUser />} />
        <Route path="/profile" element={<Profile user={userData} />} />
      </Routes>
    </div>
  );
}

// Wrap App in Router here to ensure useNavigate works correctly
function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default WrappedApp;
