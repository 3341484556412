import React, { useState, useEffect } from "react";
import "./AddCust.css";

function AddCust() {
  const [reqe, setReq] = useState("");
  const [mag, setMag] = useState("");
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [magasins, setMagasins] = useState([]);
  const [demandes, setDemandes] = useState([]);

  useEffect(() => {
    const fetchMagasins = async () => {
      try {
        const response = await fetch("https://apikanvan.c2i.tn:5001/api/custom/getmag");
        const data = await response.json();
        setMagasins(data || []);
      } catch (error) {
        console.error("Failed to fetch magasins:", error);
      }
    };
    fetchMagasins();
  }, []);

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const response = await fetch("https://apikanvan.c2i.tn:5001/api/custom/getcustom");
        const data = await response.json();
        setDemandes(data || []);
      } catch (error) {
        console.error("Failed to fetch demandes:", error);
      }
    };
    fetchDemandes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://apikanvan.c2i.tn:5001/api/custom/add", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ reqe }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setReq("");
        setIsSuccess(true);
      } else {
        setMessage(data.error);
        setIsSuccess(false);
      }
    } catch (error) {
      console.error("Error submitting demande:", error);
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://apikanvan.c2i.tn:5001/api/custom/mag", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mag }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage2(data.message2);
        setMag("");
        setIsSuccess(true);
      } else {
        setMessage2(data.error);
        setIsSuccess(false);
      }
    } catch (error) {
      console.error("Error submitting magasin:", error);
    }
  };

  return (
    <div className="container">
    <div className="section">
  <h2>Ajouter Magasin</h2>
  <div className="content">
    {/* Form Section */}
    <form className="for" onSubmit={handleSubmit2}>
      <div>
        <label>Nom du magasin:</label>
        <input
          type="text"
          value={mag}
          onChange={(e) => setMag(e.target.value)}
          placeholder="Enter magasin name"
          required
        />
      </div>
      <button className="btnt" type="submit">
        Ajouter Magasin
      </button>
    </form>
    {/* Table Section */}
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Nom du magasin</th>
          </tr>
        </thead>
        <tbody>
          {magasins.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  {message2 && <p className={`message ${isSuccess ? "success" : "error"}`}>{message2}</p>}
</div>


<div className="section">
  <h2>Ajouter Demande</h2>
  <div className="content">
    {/* Form Section */}
    <form className="for" onSubmit={handleSubmit}>
      <div>
        <label>Demande:</label>
        <input
          type="text"
          value={reqe}
          onChange={(e) => setReq(e.target.value)}
          placeholder="Enter customer name"
          required
        />
      </div>
      <button className="btnt" type="submit">
        Ajouter Client
      </button>
    </form>

    {/* Table Section */}
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Type de la demande</th>
          </tr>
        </thead>
        <tbody>
          {demandes.map((item, index) => (
            <tr key={index}>
              <td>{item.req}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>

  {/* Message Section */}
  {message && <p className={`message ${isSuccess ? "success" : "error"}`}>{message}</p>}
</div>

    </div>
  );
}

export default AddCust;
