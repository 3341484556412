import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import "./Users.css";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [postOptions, setPostOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ Mat: "", password: "", Post: "", Date: "" });
  const [showAddForm, setShowAddForm] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/users");
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const fetchPostOptions = async () => {
      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/custom/getMag");
        const options = response.data.map((item) => ({
          label: item.name, // Map 'name' from API to 'label' for dropdown
          value: item.name, // Use 'name' as both label and value
        }));
        setPostOptions(options);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchData(); // Initial fetch
    fetchPostOptions();
    const interval = setInterval(fetchData, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []); // No dependencies to avoid re-renders

  const handleAddUser = () => {
    setShowAddForm(true);
  };

  const handleCloseAddForm = () => {
    setShowAddForm(false);
    setNewUser({ Mat: "", password: "", Post: "", Date: "" }); // Clear the form data
  };

  const handleAddUserSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://apikanvan.c2i.tn:5001/api/users", { ...newUser, role: "User" });
      setUsers([...users, response.data]);
      setNewUser({ Mat: "", password: "", Post: "", Date: "" });
      setShowAddForm(false); // Close the add form after successful submission
      console.log("Form closed successfully after adding a user.");
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleDeleteUser = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (!confirmDelete) return;

    try {
      await axios.delete(`https://apikanvan.c2i.tn:5001/api/users/${id}`);
      setUsers(users.filter((user) => user.id !== id));
      console.log("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleUpdateUser = (user) => {
    setEditUserData(user);
    setShowEditForm(true);
  };

  const handleCloseEditForm = () => {
    setShowEditForm(false);
    setEditUserData(null); // Clear the edit user data to reset the form
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault(); // Prevent page refresh on form submission

    try {
      // Send PUT request to backend to update the user
      const response = await axios.put(`https://apikanvan.c2i.tn:5001/api/users/${editUserData.id}`, editUserData);

      // Check if the response data contains the updated user
      if (response.status === 200) {
        // Update the local state with the new user data
        const updatedUsers = users.map((user) => (user.id === editUserData.id ? { ...user, ...response.data.user } : user));

        setUsers(updatedUsers); // Update the users state with the modified list
        setShowEditForm(false); // Close the edit form
        console.log("User updated successfully:", response.data.user);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const columns = [
    { field: "Mat", headerName: "Matricule", flex: 1 },
    { field: "Post", headerName: "Magazine", flex: 1 },

    { field: "Date", headerName: "Created At", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => (
        <Button onClick={() => handleUpdateUser(params.row)} sx={{ backgroundColor: "rgb(73, 182, 30)", color: "white" }}>
          Modifier
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: (params) => (
        <Button onClick={() => handleDeleteUser(params.row.id)} sx={{ backgroundColor: "rgb(241, 53, 53, 0.541)", color: "white" }}>
          Effacer
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px" style={{ borderRadius: "80px", marginTop: "-5px" }} className="fb">
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Header title="USER" />
        <Button variant="contained" color="primary" onClick={handleAddUser} className="add-kanvan-btn">
          Ajouter Utilisateur
        </Button>
      </Box>

      <Box style={{ borderRadius: "80px", marginTop: "-25px" }} m="40px 0 0 0" height="75vh">
        <DataGrid
          rows={users}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: GridToolbar }}
          className="customDataGrid"
          sx={{
            "& .MuiDataGrid-root": { color: "white" },
            "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], color: "white" },
            "& .MuiDataGrid-cell": { color: "white" },
            "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
            "& .MuiDataGrid-footerContainer": { backgroundColor: colors.blueAccent[700] },
          }}
        />
      </Box>

      {showAddForm && (
        <div className="modal-overlay">
          <div className="edit-form">
            <h3 className="modal-title">Ajouter Utilisateur</h3>
            <form onSubmit={handleAddUserSubmit}>
              <div className="form-row">
                <div className="form-column">
                  <label>
                    Matricule:
                    <input type="text" value={newUser.Mat} onChange={(e) => setNewUser({ ...newUser, Mat: e.target.value })} required />
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>
                    Mot de passe:
                    <input type="password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} required />
                  </label>
                </div>
                <div className="form-column">
                  <label>
                    Magasin:
                    <select value={newUser.Post} onChange={(e) => setNewUser({ ...newUser, Post: e.target.value })} required>
                      <option value="">Choose</option>
                      {postOptions.map((post, index) => (
                        <option key={index} value={post.value}>
                          {post.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>
                    Crée a:
                    <input type="date" value={newUser.Date} onChange={(e) => setNewUser({ ...newUser, Date: e.target.value })} required />
                  </label>
                </div>
              </div>
              <div className="form-buttons">
                <button type="submit" className="save-btn">
                  Enregistrer
                </button>
                <button type="button" className="cancel-btn" onClick={handleCloseAddForm}>
                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showEditForm && editUserData && (
        <Box className="edit-form-overlay">
          <Box className="edit-form">
            <Typography className="titr" variant="h6">
              Edit User
            </Typography>
            <form onSubmit={handleEditFormSubmit}>
              <div className="form-row">
                <div className="form-column">
                  <label>Matricule:</label>
                  <input type="text" value={editUserData.Mat} onChange={(e) => setEditUserData({ ...editUserData, Mat: e.target.value })} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>Password:</label>
                  <input type="password" value={editUserData.password} onChange={(e) => setEditUserData({ ...editUserData, password: e.target.value })} />
                </div>
                <div className="form-column">
                  <label>
                    Magazine:
                    <select value={editUserData.Post} onChange={(e) => setEditUserData({ ...editUserData, Post: e.target.value })} required>
                      <option value="">Choose</option>
                      {postOptions.map((post, index) => (
                        <option key={index} value={post.value}>
                          {post.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>Created At:</label>
                  <input type="date" value={editUserData.Date} onChange={(e) => setEditUserData({ ...editUserData, Date: e.target.value })} />
                </div>
              </div>
              <div className="form-buttons">
                <Button type="submit" variant="contained" color="success">
                  SAVE
                </Button>
                <Button type="button" variant="contained" color="error" onClick={handleCloseEditForm}>
                  CANCEL
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Users;
