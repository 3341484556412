import React, { useState, useEffect } from "react";
import "./Profile.css";
import img from "./download.jpg";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Eye icons for toggle

const Profile = () => {
  const [userData, setUserData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h2 className="profile-title">{userData.Name || "N/A"}</h2>
        <div className="profile-avatar">
          <img src={img} alt="Profile Avatar" />
        </div>
        <div className="profile-info">
          <div className="profile-item">
            <strong>Matricule:</strong> <span>{userData.Mat || "N/A"}</span>
          </div>
          <div className="profile-item">
            <strong>Telephone:</strong> <span>{userData.Phone || "N/A"}</span>
          </div>
          <div className="profile-item">
            <strong>Role:</strong> <span>{userData.role || "N/A"}</span>
          </div>
          <div className="profile-item password-item">
            <strong>Mot de passe:</strong>
            <span>
              {showPassword ? userData.password : "••••••••"}
              <button
                type="button"
                className="toggle-password-btn"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </span>
          </div>
          <div className="profile-item">
            <strong>A Rejoins le :</strong> <span>{userData.Date || "N/A"}</span>
          </div>
        </div>
        {/* <button className="profile-edit-btn">Edit Profile</button> */}
      </div>
    </div>
  );
};

export default Profile;
