import React, { useState } from "react";
import "./Login.css";
import logo from "./Img/DRA.png";
import { FiUser } from "react-icons/fi";
import { CiLock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(""); // State to handle error messages

  const handleLogin = async (e) => {
    e.preventDefault();

    const Mat = e.target.Mat.value.trim(); // Username or Mat
    const password = e.target.password.value.trim(); // Password

    if (!Mat || !password) {
      console.error("Mat and password are required");
      return;
    }

    try {
      const response = await fetch("https://apikanvan.c2i.tn:5001/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Mat, password }), // Sending the password to the backend
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const data = await response.json();
      // console.log("Login successful", data.user);

      // Store user data and navigate accordingly based on role
      localStorage.setItem("userData", JSON.stringify(data.user));

      if (data.user.role === "admin") {
        navigate("/sidebar");
      } else if (data.user.role === "User") {
        navigate("/menuuser");
      } else {
        console.error("Unrecognized role:", data.user.role);
      }
    } catch (error) {
      console.error("Login failed:", error.message);
    }
  };

  return (
    <div className="back">
      <div className="login-container">
        <img src={logo} alt="Logo" className="login-logo" />
        <div className="content5 content-login">
          <h3 className="form-title2">Connexion</h3>
          <form id="login_form" className="login-form" noValidate onSubmit={handleLogin}>
            <div className="form-group">
              <div className="input-icon">
                <FiUser />
                <input className="form-control" type="text" placeholder="Username" name="Mat" required />
              </div>
            </div>
            <div className="form-group">
              <div className="input-icon">
                <CiLock />
                <input className="form-control" type="password" placeholder="Password" name="password" required />
              </div>
            </div>
            <button type="submit" className="btn btn-info pull-right">
            Se Connecter
            </button>
          </form>
          {/* Error message display */}
          {error && <div className="error-message">{error}</div>}
        </div>
        <div className="footer">
          <p>
            Powered by <a href="https://www.c2i.tn">C2I</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
