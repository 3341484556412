import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Default marker icon fix for React-Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

function Map() {
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to fetch the latest location data
  const fetchLocation = async () => {
    try {
      const response = await fetch("https://apikanvan.c2i.tn:5001/api/localization");
      if (!response.ok) {
        throw new Error("Failed to fetch location data");
      }
      const data = await response.json();

      // Update the location state with new data
      setLocation({ lat: data.latitude, lng: data.longitude });
    } catch (error) {
      console.error("Error fetching location from API:", error);
    }
  };

  useEffect(() => {
    // Fetch location initially
    fetchLocation();

    // Set up polling to fetch data every 5 seconds
    const intervalId = setInterval(fetchLocation, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {loading && !location ? (
        <p>Loading map...</p>
      ) : location ? (
        <MapContainer center={location} zoom={13} style={{ height: "100%", width: "100%" }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
          <Marker position={location}>
            <Popup>Real-Time Location</Popup>
          </Marker>
        </MapContainer>
      ) : (
        <p>Location data not available</p>
      )}
    </div>
  );
}

export default Map;
